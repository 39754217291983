import React, { useRef } from "react"
import { useState, useEffect } from "react"
import { css } from '@emotion/react'
import { graphql } from "gatsby"
import { IoArrowDown } from 'react-icons/io5'
import {MdPhone, MdEmail} from 'react-icons/md'
import scrollTo from 'gatsby-plugin-smoothscroll'
import Halo from '../images/halo.svg'
import Playbutton from '../images/playbutton.png'
import { Fade } from "react-awesome-reveal";
import { Helmet } from "react-helmet"
import { StaticImage,  GatsbyImage, getImage } from "gatsby-plugin-image"
import ReactMarkdown from 'react-markdown'
import ReactPlayer from 'react-player/lazy'




export default function Index({ data }) {

// Hamburger checkbox
const [checked, setChecked] = React.useState(false);

// Hide scroll when Hamburger is checked
if (checked === true) {
  var bodyClass = 'bodytag-overflow'}
  else {
    bodyClass = ''
  }


  var content = data.markdownRemark.frontmatter

    return (
        <main>
            <Helmet
            htmlAttributes={{
                lang: 'nl',
            }}
            >
            <body className={bodyClass}/>
            <title>Thuisverpleging Ommekaar - Begijnendijk</title>
            <meta name="description" content="" />
            <script src="https://identity.netlify.com/v1/netlify-identity-widget.js"></script>
            </Helmet>
            <header className="content">
                <StaticImage src="../images/logo.png" placeholder="blurred" loading="eager" alt="" objectFit="contain" className="logo"/>
                <span className="homenav">
                    <button onClick={() => scrollTo('#contact')}>Contact</button>
                    <label for="trigger">
                        <div onClick={() => {
                                    setChecked(old => !old)
                                    }} className="burger"
                        >
                           <a className="menubutton">Menu</a>
                        </div>
                    </label>
                    <input id="trigger" type="checkbox" checked={checked} class="visually-hidden"/>
                </span>
                <div onClick={() => {
                    setChecked(old => !old)
                    }} className={(checked === true ? "menu" : "menu-hidden")}>

                </div>
                <div className={(checked === true ? "navigation" : "navigation-hidden")}>
                        <div className="close" onClick={() => {
                    setChecked(old => !old)
                    }}>
                            <div className="closeline1"/>
                            <div className="closeline2"/>
                        </div>
                        <nav>
                            <h1 role="button"  onClick={() => {
                            setChecked(old => !old)
                            scrollTo('#over-ons')
                            }}>Over Ons</h1>
                            <h1 role="button"  onClick={() => {
                            setChecked(old => !old)
                            scrollTo('#team')
                            }}>Ons Team</h1>
                            <h1 role="button"  onClick={() => {
                            setChecked(old => !old)
                            scrollTo('#diensten')
                            }}>Onze Diensten</h1>
                            <h1 role="button"  onClick={() => {
                            setChecked(old => !old)
                            scrollTo('#contact')
                            }}>Contact</h1>
                            <span>
                            <a href={`tel:${content.telefoon}`}><MdPhone size={42} className="icon"/></a>
                            <a href={`mailto:${content.email}`}><MdEmail size={42} className="icon"/></a>
                            </span>
                        </nav>
                    </div>

            </header>

            <section id="hero">

                <div className="hero-text">

                <Fade direction="up" triggerOnce={true} duration={1000} cascade={true}><h1>{content.titel}</h1></Fade>
                <GatsbyImage className="bonne" placeholder="blurred" imgClassName="bonne-img" image={data.file.childImageSharp.gatsbyImageData} />
                </div>
                <Halo css={css`position: fixed; z-index: 0; left: 60%; top: -30%; width: 60vw; min-width: 500px`} />
                <Halo css={css`position: fixed; z-index: 0; right: 90%; bottom: -20%; width: 30vw; min-width: 400px`} />
                <IoArrowDown onClick={() => scrollTo('#over-ons')} size={48} className="downarrow" />


            </section>

            <section id="over-ons">
                <div className="content">
                    <h1>
                        Over ons
                    </h1>
                    <p>
                        {content.over_ons}
                    </p>
                    <div className="hidden">
                        <ReactPlayer url='https://www.youtube.com/watch?v=DPnYDzAGQH4'  playing controls={true}  light={true} playIcon={<StaticImage width={80} src="../images/playbutton.png" />} />
                    </div>
                    <div style={{width: "100%"}}>
                    <GatsbyImage  placeholder="blurred" style={{maxWidth: "640px", margin: "auto auto", marginTop: "4rem"}} image={data.markdownRemark.frontmatter.over_ons_foto.childImageSharp.gatsbyImageData} />
                    </div>
                </div>
                <IoArrowDown onClick={() => scrollTo('#team')} size={48} className="downarrow" />
            </section>

            <section id="team">
                <h1>Ons Team</h1>
                    <div className="content">
                    {content.medewerkers.map(({ foto, naam, telefoon }) => {
                    return(

                        <div className="medewerker vast">
                            <a href={`tel:${telefoon}`}>
                            <GatsbyImage className="medewerker-foto" imgClassName="medewerker-foto-img" image={foto.childImageSharp.gatsbyImageData} css={css`border-radius: 50%`} />
                            <MdPhone className="medewerker-telefoon" size={60} color="white" />
                            <h2>{naam}</h2>
                            <h2>{telefoon}</h2>

                            </a>
                        </div>

                            )
                    })}
                <h3>onze extra helpende handen</h3>
                {content.helpende_handen.map(({ foto, naam, telefoon }) => {
                    return(

                        <div className="medewerker">
                            <GatsbyImage className="medewerker-foto" imgClassName="medewerker-foto-img" image={foto.childImageSharp.gatsbyImageData} />
                            <h2>{naam}</h2>
                        </div>

                            )
                    })}

                </div>

            </section>

            <section id="diensten">
                <div className="content">
                    <h1>
                        Onze diensten
                    </h1>
                    <p>{content.onze_diensten}</p>
                    <ReactMarkdown>{content.diensten}</ReactMarkdown>

                </div>
                <IoArrowDown onClick={() => scrollTo('#contact')} size={48} className="downarrow" />
            </section>

        <section id="contact">
            <div className="content">
                <h1>Contact</h1>
                <p>{content.contact}</p>
                <address>
                    <p><ReactMarkdown>{content.bedrijfsinfo}</ReactMarkdown>
                    </p>
                </address>

                <a href={`tel:${content.telefoon}`}><button><MdPhone className="icon"/> {content.telefoon}</button></a>
                <a href={`mailto:${content.email}`}><button><MdEmail className="icon" /> {content.email}</button></a>
            </div>

            <div css={css`position: absolute; bottom: 1rem; text-align: center; left: 0; right: 0; color: white`}>met trots gemaakt door <a href="https://op-gemerkt.be">OPgemerkt</a></div>

        </section>

        </main>
    )
}

export const query = graphql`
query {
    file(name: {eq: "bonne"}) {
        childImageSharp {
            gatsbyImageData(formats: WEBP, placeholder: BLURRED)
        }
      }
    markdownRemark {
      frontmatter {
        over_ons_foto {
            childImageSharp {
              gatsbyImageData(formats: WEBP, placeholder: BLURRED)
            }
          }
        medewerkers {
            foto {
                childImageSharp {
                    gatsbyImageData(formats: WEBP)
                    }
            }
            naam
            telefoon
          }
        helpende_handen {
            foto {
                childImageSharp {
                    gatsbyImageData(formats: WEBP)
                    }
            }
            naam
            telefoon
          }
        bedrijfsinfo
        contact
        email
        diensten
        onze_diensten
        over_ons
        telefoon
        titel
      }
    }
  }
  `
